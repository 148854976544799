html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow: hidden;
}

#canvas {
  background-color: #424242;
}
